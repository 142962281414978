<template>
  <mi-tr class="validation-result-item" :selected="expanded">
    <mi-td v-for="col in cols" :key="col.name">
      <!-- Validation status -->
      <div v-if="col.name === 'status'" class="flex items-center no-wrap">
        <test-case-validation-results-status-item
          :col="col"
          :expanded="expanded"
          @click:close="$emit('click:close')"
          @click:why="$emit('click:why')"
        >
        </test-case-validation-results-status-item>
      </div>

      <template v-else> {{ col.value || '-' }} </template>
    </mi-td>
  </mi-tr>
</template>

<script>
  import TestCaseValidationResultsStatusItem from './TestCaseValidationResultsStatusItem.vue'

  export default {
    name: 'TestCaseValidationResultsItem',
    components: { TestCaseValidationResultsStatusItem },
    props: {
      cols: {
        type: Array,
        required: true
      },
      expanded: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['click:close', 'click:why']
  }
</script>
