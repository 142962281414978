<template>
  <mi-dialog
    :model-value="isModalOpen"
    :close-icon="false"
    :inner-loading="false"
    :maximized="false"
    :persistent="true"
    :custom-design-dialog="true"
    no-esc-dismiss
    no-backdrop-dismiss
    class="compare-validations-results-modal"
  >
    <div class="modal-header-wrapper">
      <div class="modal-header-text">
        <h3 class="q-mt-none q-mb-sm modal-header">Compare this validation with...</h3>
        <mi-btn
          icon="close"
          icon-type
          icon-size="18px"
          fab
          flat
          @click="handleCloseModal"
        ></mi-btn>
      </div>
      <div class="modal-content">
        <p class="modal-content__header">What validation do you want to compare with?</p>
        <mi-select
          v-model="testCaseName"
          :display-value="testCaseName"
          class="q-mb-sm mi-select-margin modal-content__test-case"
          label="Test case"
          :options="[]"
          outlined
          :disable="true"
        >
        </mi-select>
        <div>
          <div class="connector"></div>
          <mi-select
            v-model="selectedValidation"
            class="q-mb-sm mi-select-margin modal-content__validation-options"
            label="Validation to compare"
            :options="validations"
            option-label="formatted"
            outlined
            @update:model-value="selectValidationToCompare"
          >
            <template #no-option>
              <mi-list-item>
                <mi-list-item-section class="text-grey">
                  No validation available from this test case.
                </mi-list-item-section>
              </mi-list-item>
            </template>
          </mi-select>
        </div>
      </div>
      <div class="modal-actions">
        <mi-btn flat @click="handleCloseModal"> Cancel </mi-btn>
        <mi-btn @click="redirectToComparisonPage"> Compare </mi-btn>
      </div>
    </div>
  </mi-dialog>
</template>

<script>
  import MiDialog from '@/packages/@mi-library/MiDialog/MiDialog.vue'
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'
  import { onBeforeMount, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { useRouteParams } from '@/composables/useRouteParams'
  import formatDate from '@/utils/formatDate'
  import { TEST_CASE_VALIDATION_SUCCESS } from '@/constants'
  import { getTestCase, getTestCaseValidations } from '@/api'
  import { TEST_CASE_VALIDATION_COMPARISON_ROUTE } from '@/router/routeNames'

  export default {
    name: 'CompareValidationsResultsModal',
    components: {
      MiBtn,
      MiDialog
    },
    props: {
      isModalOpen: {
        type: Boolean,
        required: true
      },
      handleCloseModal: {
        type: Function,
        required: true
      }
    },
    setup(props) {
      const router = useRouter()

      const testCaseName = ref('')
      const validations = ref([])
      const selectedValidation = ref('Select a validation')

      const { routeParams } = useRouteParams()
      const testCaseId = ref(routeParams?.value.testCaseId)
      const validationId = ref(routeParams?.value.testCaseValidationId)

      const selectValidationToCompare = newValue => {
        selectedValidation.value = newValue
      }

      const filterValidations = allValidations => allValidations.filter(
        val => val.id !== validationId.value
          && val.status === TEST_CASE_VALIDATION_SUCCESS
      ).map(val => ({
        ...val,
        formatted: `${ formatDate(val.validationDate) } - ${ val.businessName } - ${ val.planningPeriodRange.from }`
      }))

      const redirectToComparisonPage = () => {
        if (selectedValidation.value.id) {
          props.handleCloseModal()
          router.push({
            name: TEST_CASE_VALIDATION_COMPARISON_ROUTE.name,
            params: {
              testCaseId: testCaseId.value,
              mainValidationId: validationId.value,
              comparedValidationId: selectedValidation.value.id
            }
          })
        }
      }

      onBeforeMount(async () => {
        if (testCaseId.value && validationId.value) {
          try {
            const fetchData = Promise.all([
              getTestCase(testCaseId.value),
              getTestCaseValidations(testCaseId.value)
            ])
            const [testCase, allValidations] = await fetchData
            testCaseName.value = testCase.name
            validations.value = filterValidations(allValidations)
          }
          catch (error) {
            console.error(error)
          }
        }
      })

      return {
        testCaseName,
        validations,
        selectedValidation,
        selectValidationToCompare,
        redirectToComparisonPage,
        filterValidations
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mi-field {
    margin: 16px;
  }

  .modal-header {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    width: 720px;
  }

  .modal-header-wrapper {
    display: flex;
    gap: 32px;
    flex-direction: column;
    color: $mi-anthracite-800;
    padding: 16px 24px;
  }

  .modal-header-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $mi-anthracite-800;
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }

  .modal-content {
    background-color: $mi-anthracite-50;

    &__header {
      margin: 16px;
      font-family: $mi-typography-font-family-condensed;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      text-align: left;
    }

    &__validation-options {
      margin-left: 49px;

      ::v-deep(.q-field__inner) {
        background: $mi-white;
      }
    }
  }

  .connector {
    position: absolute;
    width: 19px;
    height: 39px;
    border-left: 2px solid $mi-anthracite-800;
    border-bottom: 2px solid $mi-anthracite-800;
    margin-left: 26px;
  }

  @media (min-width: 600px) {
    .q-dialog__inner--minimized > div {
      max-width: 628px;
    }
  }
</style>
