<template>
  <div class="header-validation-results-wrapper ">
    <h3 class="header">
      Validation Results
    </h3>
    <div class="btns-validation-results-header">
      <mi-btn
        v-if="!isComparisonPage"
        icon-right-size="16px"
        class="compare-results-btn"
        @click="handleOpenModal"
      >
        Compare results
      </mi-btn>
      <mi-btn
        v-else
        icon="close"
        icon-size="16px"
        class="compare-results-btn"
        @click="handleStopComparing"
      >
        Stop Comparing
      </mi-btn>
      <mi-btn
        :loading="isValidationExporting"
        icon-right="cloud-download"
        icon-right-size="16px"
        class="export-test-case-btn"
        @click="exportValidationResultsExcel()"
      >
        Export Excel
      </mi-btn>
    </div>
  </div>
  <compare-validations-results-modal
    :handle-close-modal="handleCloseModal"
    :is-modal-open="isModalOpen"
  ></compare-validations-results-modal>
</template>

<script>
  import { ref } from 'vue'
  import CompareValidationsResultsModal
    from '@/components/validations/test-cases/validation-results/CompareValidationsResultsModal.vue'
  import { useRouter } from 'vue-router'
  import { useRouteParams } from '@/composables/useRouteParams'
  import { TEST_CASE_VALIDATION_RESULTS_ROUTE } from '@/router/routeNames'
  import { exportTestCaseValidation } from '@/api'
  import { exportFile } from 'quasar'

  export default {
    name: 'ValidationResultsHeader',
    components: { CompareValidationsResultsModal },
    props: {
      isComparisonPage: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    setup() {
      const isModalOpen = ref(false)
      const router = useRouter()
      const { routeParams } = useRouteParams()
      const isValidationExporting = ref(false)

      const handleOpenModal = () => {
        isModalOpen.value = true
      }

      const handleCloseModal = () => {
        isModalOpen.value = false
      }

      const handleStopComparing = () => {
        router.push({
          name: TEST_CASE_VALIDATION_RESULTS_ROUTE.name,
          params: {
            testCaseId: routeParams.value.testCaseId,
            testCaseValidationId: routeParams.value.mainValidationId
          }
        })
      }

      const exportValidationResultsExcel = async (prefix = 'Test_case') => {
        const validationId = routeParams.value.testCaseValidationId || routeParams.value.mainValidationId
        isValidationExporting.value = true

        try {
          const excelFile = await exportTestCaseValidation(validationId)
          const filename = `${ prefix }_validation_${
            (validationId)
          }.xlsx`

          exportFile(filename, new Blob([excelFile]))
        }
        finally {
          isValidationExporting.value = false
        }
      }

      return {
        isModalOpen,
        handleOpenModal,
        handleCloseModal,
        handleStopComparing,
        exportValidationResultsExcel,
        isValidationExporting
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-validation-results-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 58px;
  }
</style>
