<template>
  <mi-badge :color="col.value.color" class="text-uppercase q-mr-sm">
    {{ col.value.title }}
  </mi-badge>

  <div
    v-if="col.value.title === statusResults.FAILED.title"
    :class="comparisonResults ? 'q-ml-md' : 'q-ml-auto'"
  >
    <!-- Close explanation button -->
    <mi-btn
      v-if="expanded"
      class="validation-result-item__btn text-overline"
      icon="close"
      icon-size=".875rem"
      fab
      icon-type
      flat
      @click="$emit('click:close')"
    ></mi-btn>

    <!-- Why explanation button -->
    <mi-btn
      v-else
      class="validation-result-item__btn"
      color="accent"
      dense
      @click="$emit('click:why')"
    >
      Why
    </mi-btn>
  </div>
</template>

<script>
  import { RESULT_STATUS } from '@/constants'
  import { ref } from 'vue'

  export default {
    name: 'TestCaseValidationResultsStatusItem',
    props: {
      col: {
        type: Object,
        required: true
      },
      expanded: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['click:close', 'click:why'],
    setup() {
      const statusResults = ref(RESULT_STATUS)

      return {
        statusResults
      }
    }
  }
</script>

<style lang="scss" scoped>
  .validation-result-item__btn.mi-btn {
    min-height: 1.75rem;

    &.q-btn--fab {
      min-width: 1.75rem;
      padding: .25rem;
    }
  }
</style>
